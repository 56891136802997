import React from "react";
import { useStore } from "src/store";
import { Spacer } from "../sidebar/contents/dynamic.components";

const TradeMark = ({}) => {
  const { closeOverlay } = useStore();
  return (
    <>
      <div
        className="c-cytiva__trademark-section"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="c-cytiva__trademark-top">
          <img
            className="c-cytiva__trademark-top__logo"
            src="favicon.ico"
            width="40"
            alt="Cytiva logo"
          />
          <button
            className="c-cytiva__trademark-top__close"
            onClick={closeOverlay}
          >
            X
          </button>
        </div>
        <p className="c-cytiva__trademark-text">
          Cytiva and the Drop logo are trademarks of Global Life Sciences IP
          Holdco LLC or an affiliate.
          <Spacer />
          Biacore; Biacore Multi Cycle Kinetics (MCK), Biacore Single Cycle
          Kinetics (SCK) and Biacore Intelligent Analysis are trademarks of
          Global Life Sciences Solutions USA LLC or an affiliate doing business
          as Cytiva.
          <Spacer />
          © 2023 Cytiva.
          <Spacer />
          All goods and services are sold subject to the terms and conditions of
          sale of the supplying company operating within the Cytiva business. A
          copy of those terms and conditions is available on request.
          <Spacer />
          Contact your local Cytiva representative for the most current
          information.
          <Spacer />
          For local office contact information, visit:{" "}
          <a
            className="c-cytiva__trademark-text"
            style={{ textDecoration: "none" }}
            href="https://cytiva.com/contact"
            target="_blank"
          >
            cytiva.com/contact
          </a>
        </p>
      </div>
    </>
  );
};

export default TradeMark;
