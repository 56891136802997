import React from "react";
import { cameraMoveTiming } from "src/constants";
import { setAnimationFrame, switchToModel } from "src/services/animations";
import { playHotspot } from "src/services/annotations";
import { useStore } from "src/store";
import { SubLink, TreeDModel, SidebarVisibility } from "src/types/index.d";

type SubLinkProps = {
  subLink: SubLink;
};

const SubLinkComponent: React.FC<SubLinkProps> = ({ subLink }) => {
  const { setActiveSubLink, setSidebarStatus, changeModel } = useStore();

  const delayToOpenSidebar = subLink.annotations ? cameraMoveTiming * 1000 : 0;

  const onSubLinkClick = () => {
    setActiveSubLink(subLink);
    if (subLink.annotations) {
      if (
        subLink.staticFrameWithHotspot &&
        subLink.staticFrameWithHotspot.length === 1
      ) {
        subLink.staticFrameWithHotspot &&
          setAnimationFrame(subLink.staticFrameWithHotspot[0]);
      } else {
        playHotspot(subLink.annotations[0]);
      }
      if (subLink.id === "3-4") {
        changeModel(TreeDModel.eight_K_Plus);
      }
    }

    setTimeout(() => {
      setSidebarStatus(SidebarVisibility.show);
    }, delayToOpenSidebar);
  };

  return (
    <li className="c-cytiva__sub-list__item">
      <button
        className="button--fixwidth-large button button--primary button--large button--inline"
        onClick={onSubLinkClick}
      >
        {subLink.text}
      </button>
    </li>
  );
};

export default SubLinkComponent;
