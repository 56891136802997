import React, { useEffect } from "react";
import { Title, Text, Image, Spacer, CustomLink } from "../dynamic.components";
import { useStore } from "src/store";
import { playCustomAnimation } from "src/services/animations";
import {
  componentAnimationReadyToPlay,
  componentAnimationReadyToStop,
} from "src/utils/helper";
import parallel_analysis from "src/assets/images/2_1_Reproducibility_Parallel_analysis.jpg";

export const ParallelAnalysis: React.FC = () => {
  const {
    sidebarStatus,
    animationStopped,
    setAnimationStopped,
    activeSubLink: { text },
  } = useStore();

  useEffect(() => {
    if (componentAnimationReadyToPlay("1-4")) {
      playCustomAnimation();
    }
    if (componentAnimationReadyToStop("1-4")) {
      setAnimationStopped(true);
    }
  }, [sidebarStatus, animationStopped]);

  return (
    <>
      <Title title={text} />
      <div className="c-sidebar__body">
        <Text
          text="Biacore 8 series systems feature an eight-channel parallel setup with a microfluidic injection concept which enables each channel to provide high quality,
                reference-subtracted data. The simple 8 × 2 flow cell-setup
                makes planning, preparation, and operation straightforward
                and easy to understand."
        />
        <Spacer x={3} />
        <Image
          images={[
            {
              src: parallel_analysis,
              alt: "Productivity parallel analysis image",
              customWidth: "70%",
            },
          ]}
        />
      </div>
    </>
  );
};
