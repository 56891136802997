import React, { FC } from "react";
import { useStore } from "../../store";
import { SubLinkId } from "../../data";
import { Microfluidics } from "./contents/precision-sensitivity/Microfluidics";
import { SyringePumps } from "./contents/precision-sensitivity/SyringePumps";
import { BufferInlets } from "./contents/precision-sensitivity/BufferInlets";
import { TemperatureControl } from "./contents/precision-sensitivity/TemperatureControl";
import { RobustResults } from "./contents/reproducibility/RobustResults";
import { ParallelAnalysis } from "./contents/reproducibility/ParallelAnalysis";
import { FlowCells } from "./contents/increaced-capacity/FlowCells";
import { SampleInjectionTools } from "./contents/increaced-capacity/SampleInjectionTools";
import { MethodTools } from "./contents/increaced-capacity/MethodTools";
import { SampleHotel } from "./contents/increaced-capacity/SampleHotel";
import { ExpandAffinity } from "./contents/expand-affinity/ExpandAffinity";
import { InsightSoftware } from "./contents/ease-of-use/InsightSoftware";
import { TrainingTools } from "./contents/ease-of-use/TrainingTools";

const Content: FC = (): JSX.Element => {
  const { activeSubLink } = useStore();
  switch (activeSubLink.id) {
    case SubLinkId.microfluidics:
      return <Microfluidics />;
    case SubLinkId.syringePumps:
      return <SyringePumps />;
    case SubLinkId.bufferInlets:
      return <BufferInlets />;
    case SubLinkId.temperatureControl:
      return <TemperatureControl />;
    case SubLinkId.robustResults:
      return <RobustResults />;
    case SubLinkId.parallelAnalysis:
      return <ParallelAnalysis />;
    case SubLinkId.flowCells:
      return <FlowCells />;
    case SubLinkId.sampleInjectionTools:
      return <SampleInjectionTools />;
    case SubLinkId.methodTools:
      return <MethodTools />;
    case SubLinkId.sampleHotel:
      return <SampleHotel />;
    case SubLinkId.expandBeyondAffinity:
      return <ExpandAffinity />;
    case SubLinkId.insightSoftware:
      return <InsightSoftware />;
    case SubLinkId.trainingTools:
      return <TrainingTools />;
    default:
      return <></>;
  }
};

const SidebarContent: FC = (): JSX.Element => {
  return (
    <div className="c-sidebar__wrapper">
      <Content />
    </div>
  );
};

export default SidebarContent;
