import React from "react";
import {
  Title,
  Text,
  Image,
  Spacer,
  CustomLink,
  Subtitle,
} from "../dynamic.components";
import { useStore } from "src/store";
import training_tools from "src/assets/images/5_2_Ease_of_use_Training_tools.png";

export const TrainingTools: React.FC = () => {
  const { text } = useStore().activeSubLink;
  return (
    <>
      <Title title={text} />
      <div className="c-sidebar__body">
        <Text text="With our library of application guides and lab procedures — you can add free online learning courses that help you to sharpen your skills in SPR analysis. Learn more about SPR technology, how to get started, how to design your assay, evaluate your data and how to work with and use Biacore systems." />
        <Text text="Our experienced application scientists can guide you through your SPR journey and organize and facilitate local courses (classroom or virtual)." />
        <Spacer x={3} />
        <Image
          images={[
            {
              src: training_tools,
              alt: "Ease of use training tools",
              customWidth: "90%",
            },
          ]}
        />
        <Spacer x={2} />
        <Subtitle subtitle="Links / Resources:" />
        <Spacer />
        <CustomLink
          href="https://www.cytivalifesciences.com/education/online-learning/courses?sort=name&phrase=spr&type=all"
          text="Online courses"
        />
        <CustomLink
          href="https://www.cytivalifesciences.com/solutions/protein-research/Interaction-analysis-with-Biacore-surface-plasmon-resonance-SPR/Get-started-with-surface-plasmon-resonance-SPR-interaction-analysis#Applicationguideshandbook"
          text="Application guides and handbooks"
        />
        <CustomLink
          href="https://www.cytivalifesciences.com/solutions/protein-research/Interaction-analysis-with-Biacore-surface-plasmon-resonance-SPR/Get-started-with-surface-plasmon-resonance-SPR-interaction-analysis#labprocedures"
          text="Lab procedures"
        />
        <CustomLink
          href="https://www.cytivalifesciences.com/support/online-tools/Biacore-surface-plasmon-resonance"
          text="Calculators and tools"
        />
        <Spacer x={3} />
      </div>
    </>
  );
};
