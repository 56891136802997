import React, { useEffect } from "react";
import { useStore } from "src/store";
import { SidebarVisibility, TreeDModel } from "src/types/index.d";
import { Title, Spacer, List, Text, Image } from "../dynamic.components";
import capacity_sample_hotel from "src/assets/images/3_4_Increased_capacity_Sample_hotel.jpg";
import {
  componentAnimationReadyToPlay,
  componentAnimationReadyToStop,
} from "src/utils/helper";
import { playAnimationByModel } from "src/services/animations";

export const SampleHotel: React.FC = (): JSX.Element => {
  const {
    activeSubLink: { text },
    animationStopped,
    setAnimationStopped,
    sidebarStatus,
    changeModel,
    activeModel,
    animationIsPlaying,
  } = useStore();

  useEffect(() => {
    if (componentAnimationReadyToPlay("3-4")) {
      playAnimationByModel(activeModel);
    }
    if (componentAnimationReadyToStop("3-4")) {
      setAnimationStopped(true);
    }
  }, [sidebarStatus, animationStopped]);

  useEffect(() => {
    if (sidebarStatus === SidebarVisibility.show) {
      playAnimationByModel(activeModel);
    }
  }, [activeModel, sidebarStatus]);

  return (
    <>
      <Title title={text} />
      <div className="c-sidebar__body">
        <Spacer x={3} />
        <button
          onClick={() => {
            changeModel(TreeDModel.eight_K_Plus);
          }}
          className={`button button--secondary button--xlarge button--fixwidth ${
            activeModel === TreeDModel.eight_K_Plus && "button--hover"
          }`}
          disabled={animationIsPlaying}
        >
          Biacore™&nbsp;8K+
        </button>
        <Spacer />
        <button
          onClick={() => {
            changeModel(TreeDModel.eight_K);
          }}
          className={`button button--secondary button--xlarge button--fixwidth ${
            activeModel === TreeDModel.eight_K && "button--hover"
          }`}
          disabled={animationIsPlaying}
        >
          Biacore™&nbsp;8K
        </button>
        <Spacer />
        <Text text="Sample and reagents are placed in microplates, 96-or 384- well standard or deep-well microplates. The microplate is placed on a sample tray. Each sample tray can hold two microplates. Biacore 8K is equipped with two sample trays and can hold up to four microplates, Biacore 8K+ is equipped with six sample trays and can hold up to twelve microplates." />
        <Spacer x={2} />
        <Text text="Biacore 8K" />
        <List
          listArray={["Number of sample trays: 2", "Number of microplates: 4"]}
        />
        <Spacer x={2} />
        <Text text="Biacore 8K+" />
        <List
          listArray={["Number of sample trays: 6", "Number of microplates: 12"]}
        />
        <Spacer x={2} />
        <Image
          images={[
            {
              src: capacity_sample_hotel,
              alt: "increased capacity sample hotel image",
              customWidth: "90%",
            },
          ]}
        />
      </div>
    </>
  );
};
