import { useStore } from "src/store";
import biacore8k from "src/assets/images/biacore8k.png";
import biacore8kplus from "src/assets/images/biacore8kplus.png";
import whiteLogo from "src/assets/images/logo-white.png";
import { deviceName } from "src/constants";

const Loading = () => {
  const { viewIsReady } = useStore();

  return (
    <>
      {!viewIsReady && (
        <div className="c-cytiva__loading">
          <div className="c-cytiva__loading-top">
            <img
              className="c-cytiva__loading-top__logo"
              src={whiteLogo}
              alt=""
            />
            {/* <h3 className="c-cytiva__loading-top__brand">Cytiva</h3> */}
          </div>
          <div className="c-cytiva__loading-images">
            <img src={biacore8k} alt="" />
            <img src={biacore8kplus} alt="" />
          </div>

          <div>
            <div className="loader-container">
              <div className="loader"></div>
            </div>
            <h3 className="c-cytiva__loading-name">{deviceName}</h3>
          </div>
        </div>
      )}
    </>
  );
};
export default Loading;
