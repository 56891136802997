import React, { useEffect } from "react";
import { playCustomAnimation } from "src/services/animations";
import { useStore } from "src/store";
import {
  componentAnimationReadyToPlay,
  componentAnimationReadyToStop,
} from "src/utils/helper";
import { Title, Text, Spacer, Image, TextInline } from "../dynamic.components";
import capacity_method_tools_c515 from "src/assets/images/8series_c515.png";
import capacity_method_tools_c220 from "src/assets/images/8series_c220.png";

export const MethodTools: React.FC = () => {
  const {
    sidebarStatus,
    animationStopped,
    setAnimationStopped,
    activeSubLink: { text },
  } = useStore();

  useEffect(() => {
    if (componentAnimationReadyToPlay("3-3")) {
      playCustomAnimation();
    }
    if (componentAnimationReadyToStop("3-3")) {
      setAnimationStopped(true);
    }
  }, [sidebarStatus, animationStopped]);

  return (
    <>
      <Title title={text} />
      <div className="c-sidebar__body">
        <TextInline text="Operational efficiency may be significantly improved by adding ligand attachment (immobilization) methods, analysis methods, cleaning procedures, temperature changes, and other relevant steps to the" />{" "}
        <TextInline style="italic" weight="bold" text="Activity queue" />{" "}
        <TextInline text="in a fully flexible manner, minimizing waiting time." />{" "}
        <Spacer />
        <Text text="Load sample and reagents to the sample hotel at any time, except when the sample tray is transferred between the sample hotel and sample compartment." />
        <Spacer x={2} />
        <Image
          images={[
            {
              src: capacity_method_tools_c515,
              alt: "increased capacity method tools C515 image",
              customWidth: "80%",
            },
            {
              src: capacity_method_tools_c220,
              alt: "increased capacity method tools C220 image",
              customWidth: "80%",
            },
          ]}
        />
      </div>
    </>
  );
};
