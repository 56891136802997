import React, { useEffect, useState } from "react";
import { setAnimationFrame } from "src/services/animations";
import { useStore } from "src/store";
import {
  componentAnimationReadyToPlay,
  componentAnimationReadyToStop,
} from "src/utils/helper";
import { Title, Text, Spacer, Image } from "../dynamic.components";
import parallel_analysis from "src/assets/images/2_1_Reproducibility_Parallel_analysis.jpg";
import flow_cells from "src/assets/images/3_1_Increased_capacity_Flow-cells.png";

export const FlowCells: React.FC = () => {
  const [state, setState] = useState(0);

  const {
    sidebarStatus,
    animationStopped,
    setAnimationStopped,
    activeSubLink: { text, extras, staticFrameWithHotspot },
  } = useStore();

  useEffect(() => {
    if (componentAnimationReadyToPlay("3-4")) {
      staticFrameWithHotspot && setAnimationFrame(staticFrameWithHotspot[0]);
      setState(0);
    }
    if (componentAnimationReadyToStop("3-4")) {
      // setAnimationStopped(true);
    }
  }, [sidebarStatus, animationStopped]);

  return (
    <>
      <Title title={text} />
      <div className="c-sidebar__body">
        <Text text="Biacore 8 series is an easy to use, eight needle, eight channel instrument platform with two flow cells per channel to simplify assay setup and operation of Biacore 8 series." />
        <Spacer />
        <Text text="Utilizing parallel setup during assay design with Method builder, or Interactive run, is cost effective, increases the analytical flexibility, and shortens time in assay development." />
        <Spacer x={2} />
        <Text text="The high repeatability between channels with a CV < 2% enables broad use of parallel configuration. Additionally you can utilize the 16 flow cells for epitope binning of 16 different immobilized antibodies, or generate an eight-point calibration curve in duplicate." />

        <Spacer x={3} />
        <Image
          images={[
            {
              src: parallel_analysis,
              alt: "Productivity rebust results image",
              customWidth: "60%",
            },
            {
              src: flow_cells,
              alt: "Increased capacity flow cells image",
              customWidth: "80%",
            },
          ]}
        />
      </div>
    </>
  );
};
