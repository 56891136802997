import React from "react";
import { useStore } from "src/store";
import { Title, Text, Spacer } from "../dynamic.components";

export const BufferInlets: React.FC = (): JSX.Element => {
  const {
    activeSubLink: { text },
  } = useStore();

  return (
    <>
      <Title title={text} />
      <div className="c-sidebar__body">
        <Text text="Running buffer and distilled water are supplied from bottles placed on the bench at the right of the instrument. Smaller bottles and tubes may be placed in the accessory holders attached to the holder rail." />
        <Spacer />
        <Text text="Biacore 8 series is equipped with a buffer selector so you can change up to four running buffers without manual intervention — allowing you to queue activities and methods." />
        <Spacer x={2} />
        <Text text="Note. Use a 0.22µm filter when preparing buffers to avoid introducing biological growth into the flow system." />
      </div>
    </>
  );
};
