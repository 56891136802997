import React from "react";
import { Title, Text, Spacer, Image } from "../dynamic.components";
import { useStore } from "src/store";
import expand_beyond_affinity from "src/assets/images/4_1_Expand_beyond_affinity.png";
import parallel_kinetics from "src/assets/images/Parallel kinetics.png";
import kinetics_2D from "src/assets/images/2D kinetics.png";
import mck from "src/assets/images/MCK.png";
import sck from "src/assets/images/SCK.png";

export const ExpandAffinity: React.FC = () => {
  const { text } = useStore().activeSubLink;

  return (
    <>
      <Title title={text} />
      <div className="c-sidebar__body">
        <Text text="Biacore systems allow you to visualize your research and view molecular interactions in real-time using surface plasmon resonance technology." />
        <Spacer />

        <Text text="Biacore 8 series is a single solution for efficient binding anlysis of small molecules and biotherapeutics. Add-on application specific extensions maximize platform versatility, improve visualization, and decrease time to result. " />
        <Spacer />

        <Text text="Biacore systems have a wide dynamic range which enables assessment of binding rate constants and active concentration for basically all biological interactions." />
        <Spacer />

        <Text text="Biacore 8 series has multiple possibilities on how to perform kinetic analysis. Depending on the preferences and circumstances of each assay, kinetic analysis can be performed in four different ways." />
        <Spacer />
        <Text text="- Biacore Multi Cycle Kinetics (MCK)™ is an assay format where each analyte concentration is injected in a separate cycle. Suitable when different ligands are to be immobilized or for kinetic analysis of many samples against one ligand." />
        <Spacer />
        <Image
          images={[
            {
              src: mck,
              alt: "MCK image",
              customWidth: "100%",
            },
          ]}
        />
        <Spacer x={2} />

        <Text text="- Biacore Single Cycle Kinetics (SCK)™ is an assay format where analyte concentrations (2 - 5 per injection) are injected in sequence in a single cycle. The time to results is fast and regeneration is not needed. Often used for interactions with long dissociation times or kinetic screening." />
        <Spacer />
        <Image
          images={[
            {
              src: sck,
              alt: "SCK image",
              customWidth: "100%",
            },
          ]}
        />
        <Spacer x={2} />

        <Text text="- 2D Kinetics functionality offers kinetic analysis from a single sample cycle without prior knowledge of the characteristics of the interaction, saving valuable assay development time" />
        <Spacer />
        <Image
          images={[
            {
              src: kinetics_2D,
              alt: "2D kinetics image",
              customWidth: "100%",
            },
          ]}
        />
        <Spacer x={2} />

        <Text text="- Parallel Kinetics is an assay format where each analyte concentration is injected in a different channel in the same cycle. It offers short run time for few samples and you can get kinetic analysis in only two cycles (one blank cycle). Beneficial for samples with long dissociation times." />
        <Spacer />
        <Image
          images={[
            {
              src: parallel_kinetics,
              alt: "Parallel kinetics image",
              customWidth: "100%",
            },
          ]}
        />
      </div>
    </>
  );
};
