import { deviceName } from "src/constants";
import { useStore } from "src/store";
import { SidebarVisibility } from "src/types/index.d";

const Name = () => {
  const { viewIsReady, sidebarStatus } = useStore();

  return (
    <>
      {viewIsReady && sidebarStatus !== SidebarVisibility.show && (
        <div className="c-cytiva__device-name">{deviceName}</div>
      )}
    </>
  );
};
export default Name;
