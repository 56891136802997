import React, { useEffect } from "react";
import {
  Title,
  Text,
  Image,
  Spacer,
  CustomLink,
  List,
  Subtitle,
} from "../dynamic.components";
import { useStore } from "src/store";
import biacore_systems from "src/assets/images/2_1_Reproducibility_Robust_results.png";
import {
  componentAnimationReadyToPlay,
  componentAnimationReadyToStop,
} from "src/utils/helper";
import { playCustomAnimation } from "src/services/animations";

export const RobustResults: React.FC = () => {
  const {
    activeSubLink: { text },
    animationStopped,
    sidebarStatus,
    setAnimationStopped,
  } = useStore();

  useEffect(() => {
    if (componentAnimationReadyToPlay("2-1")) {
      playCustomAnimation();
    }
    if (componentAnimationReadyToStop("2-1")) {
      setAnimationStopped(true);
    }
  }, [sidebarStatus, animationStopped]);

  return (
    <>
      <Title title={text} />
      <div className="c-sidebar__body">
        <Text text="Cytiva has developed a range of tools designed specifically to make Biacore assays as easy and reliable as possible, suitable for users of all levels of experience." />
        <Text text="The complete toolbox is backed by stringent development, production methods and quality control." />
        <Spacer x={2} />
        <Text text="Biacore 8 series uses Biacore Series S sensor chips, which offer support for analysis of a wide range of applications and molecules." />
        <Text text="A variety of already functionalized sensor chips and capture kits offer several options for capturing most common antibodies and molecule tags." />
        <Text text="This in combination with lab protocols and predefined methods, significantly reduces the time and effort you need to spend on developing your assay." />
        <Text text="Ready-made buffers and solutions are available for your convenience, so your assay conditions are the same for every experiment." />
        <Spacer x={2} />
        <Image
          images={[
            {
              src: biacore_systems,
              alt: "Productivity rebust results image",
              customWidth: "80%",
            },
          ]}
        />
        <Spacer x={2} />
        <Subtitle subtitle="Resources:" />
        <Spacer />
        <CustomLink
          href="https://www.cytivalifesciences.com/solutions/protein-research/interaction-analysis-with-biacore-surface-plasmon-resonance-spr/biacore-selection-tool"
          text="Biacore™ consumables product guide"
        />
        <Spacer />
        <CustomLink
          href="https://cdn.cytivalifesciences.com/api/public/content/RMPZCzeRxke2iEQwfpXQOw-pdf"
          text="Biotin CAPture Kit ― Ready-to-go interaction analysis (infographic)"
        />
        <Spacer x={2} />
      </div>
    </>
  );
};
