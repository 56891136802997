import { CustomLink } from "../types";

export enum SubLinkId {
  microfluidics = "1-1",
  syringePumps = "1-2",
  bufferInlets = "1-3",
  temperatureControl = "1-4",

  robustResults = "2-1",
  parallelAnalysis = "2-2",

  flowCells = "3-1",
  sampleInjectionTools = "3-2",
  methodTools = "3-3",
  sampleHotel = "3-4",

  expandBeyondAffinity = "4-1",

  insightSoftware = "5-1",
  trainingTools = "5-2",
}

export const links: CustomLink[] = [
  {
    id: "1",
    title: "Precision for high sensitivity",
    hasSublinks: true,
    // annotations: ["1_1_microfluidics"],
    subLinks: [
      {
        id: "1-1",
        text: "Microfluidics",
        annotations: ["1_1_microfluidics"],
        staticFrameWithHotspot: [
          {
            annotation: "1_1_microfluidics",
            frame: 66,
          },
        ],
      },
      {
        id: "1-2",
        text: "Syringe pumps",
        annotations: ["1_2_pumps"],
        animations: ["Scene"],
        animationSequences: [[58, 64]],
        staticFrameWithHotspot: [
          {
            annotation: "1_2_pumps",
            frame: 58,
          },
        ],
      },
      {
        id: "1-3",
        text: "Buffer inlets",
        annotations: ["1_3_buffer-inlets"],
        staticFrameWithHotspot: [
          {
            annotation: "1_3_buffer-inlets",
            frame: 52,
          },
        ],
      },
      {
        id: "1-4",
        text: "Temperature control",
        annotations: ["1_4_temp-ctrl"],
        staticFrameWithHotspot: [
          {
            annotation: "1_4_temp-ctrl",
            frame: 20,
          },
        ],
      },
    ],
  },
  {
    id: "2",
    title: "Reproducibility",
    hasSublinks: false,
    // annotations: ["BioReactor"],
    subLinks: [
      {
        id: "2-1",
        text: "Robust results",
        annotations: ["2_1_robust"],
        animations: ["Scene"],
        animationSequences: [[45.3, 50]],
        staticFrameWithHotspot: [
          {
            annotation: "2_1_robust",
            frame: 45.3,
          },
        ],
      },
      {
        id: "2-2",
        text: "Parallel analysis",
        annotations: ["2_2_parallel"],
      },
    ],
  },
  {
    id: "3",
    title: "Increased capacity",
    // annotations: ["CabinetAssembly"],
    animationSequences: [],
    hasSublinks: true,
    subLinks: [
      {
        id: "3-1",
        text: "Flow cells",
        annotations: ["3_1_flow-cells"],
        staticFrameWithHotspot: [
          {
            annotation: "3_1_flow-cells",
            frame: 67,
          },
        ],
      },
      {
        id: "3-2",
        text: "Sample injection tools",
        animations: ["Scene"],
        animationSequences: [
          [21, 24],
          [24, 29],
          [29, 35],
          [35, 44],
        ],
        annotations: [
          "3_2_sample_injection",
          "3_2_sample_injection_2",
          "0_WINDOW_1",
          "0_WINDOW_2",
        ],
        staticFrameWithHotspot: [
          {
            annotation: "3_2_sample_injection",
            frame: 21,
          },
        ],
      },
      {
        id: "3-3",
        text: "Method tools",
        annotations: ["3_3_method"],
        animations: ["Scene"],
        animationSequences: [[52, 57]],
      },
      {
        id: "3-4",
        text: "Sample hotel",
        annotations: ["3_4_sample-hotel"],
        animations: ["Scene"],
        animationSequences: [[21, 29]],
        staticFrameWithHotspot: [{ annotation: "3_4_sample-hotel", frame: 21 }],
        extras: {
          animationSequences: [[11.66, 18.59]],
        },
      },
    ],
  },
  {
    id: "4",
    title: "Expand beyond affinity",
    animationSequences: [],
    hasSublinks: true,
    subLinks: [
      {
        id: "4-1",
        text: "Expand beyond affinity",
        annotations: ["4_expand"],
      },
    ],
  },
  {
    id: "5",
    title: "Ease of use",
    animationSequences: [],
    hasSublinks: true,
    subLinks: [
      {
        id: "5-1",
        text: "Biacore Insight Software",
        annotations: ["5_1_biacore-insight-sw"],
        staticFrameWithHotspot: [
          {
            annotation: "5_1_biacore-insight-sw",
            frame: 21,
          },
        ],
      },
      {
        id: "5-2",
        text: "Training tools",
        annotations: ["5_2_training"],
        staticFrameWithHotspot: [
          {
            annotation: "5_2_training",
            frame: 70,
          },
        ],
      },
    ],
  },
];
