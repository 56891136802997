import React, { useEffect } from "react";
import { playCustomAnimation } from "src/services/animations";
import { useStore } from "src/store";
import {
  componentAnimationReadyToPlay,
  componentAnimationReadyToStop,
} from "src/utils/helper";
import { Title, Text } from "../dynamic.components";

export const SyringePumps: React.FC = (): JSX.Element => {
  const {
    sidebarStatus,
    animationStopped,
    setAnimationStopped,
    activeSubLink: { text },
  } = useStore();

  useEffect(() => {
    if (componentAnimationReadyToPlay("1-2")) {
      playCustomAnimation();
    }
    if (componentAnimationReadyToStop("1-2")) {
      setAnimationStopped(true);
    }
  }, [sidebarStatus, animationStopped]);

  return (
    <>
      <Title title={text} />
      <div className="c-sidebar__body">
        <Text text="Continuous flow of running buffer or sample over the sensor chip surface is managed by two high precision, 8 channel syringe pumps, housed inside the instrument." />
      </div>
    </>
  );
};
