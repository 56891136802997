import React from "react";
import { Title, Text, Image, Spacer, List } from "../dynamic.components";
import { useStore } from "src/store";
import ease from "src/assets/images/5_1_Ease-of-use_400px.gif";

export const InsightSoftware: React.FC = () => {
  const { text } = useStore().activeSubLink;

  return (
    <>
      <Title title={text} />
      <div className="c-sidebar__body">
        <Text text="Biacore Insight Software provides functionality for running and evaluating interaction analysis experiments. The software offers a streamlined, easy to use approach to visualize and analyze kinetic and affinity data with predefined evaluation methods and quality control tools to shorten time to decision. Easily export data and comply results for reports and presentations or transfer large data sets for aggregation and further analysis." />
        <Spacer />
        <Image
          images={[
            {
              src: ease,
              alt: "",
              customWidth: "80%",
            },
          ]}
        />
        <Text text="The flat user interface of Biacore Insight Software is compatible with both Biacore 8 series and Biacore 1 series. This makes it easy to switch between different instrument configurations and platforms with less time spent on training. Note! Connection to Biacore Insight database is required to use both the control software and the evaluation software." />
        <Spacer />
        <List
          x={1}
          listArray={[
            "Biacore Insight Control Software is installed on the instrument controlling computer. The control software can be used without a connection to the instrument (or with the instrument switched off) to create analysis methods and to open saved results. Methods are easily transferred between instrument and labs to minimize development time.",
            "Biacore Insight Evaluation Software is a stand-alone software for evaluation of results obtained from Biacore 1 series, Biacore 8 series, Biacore T200 and Biacore S200. It is normally installed on the controlling computer to which the instrument is connected, but does not require a connection to the instrument.",
          ]}
        />
        <Spacer x={2} />
        <Text text="In addition to the standard, entry level affinity and kinetics analysis — optional add-on extensions are available that add functionality and tools to expand your applications using the same instrument. " />
        <Spacer />
        <List
          x={1}
          listArray={[
            "Biacore Insight Extended Screening Extension - designed for LMW and fragment screening applications.",
            "Biacore Insight Concentration and Potency Extension – for reproducible concentration and streamlined drug potency analysis. ",
            "Biacore Insight Epitope Binning Extension for identification and control to maintain unique and diverse epitopes.",
            "Biacore Intelligent Analysis™ software – our machine learning software extension that helps to evaluate your data in minutes. Machine learning models are pretrained by our scientists and engineers. Use them to analyze large data sets for critical applications in your low molecular weight workflow",
          ]}
        />
        <Spacer x={2} />
        <Text text="Biacore 8 series can be integrated seamlessly into GxP-regulated workflows. " />
        <Spacer />
        <List
          listArray={[
            "Biacore Insight GxP Extension provides validated software supporting GLP/GCP/GMP and 21 CFR Part 11 compliance, and includes validation support.",
          ]}
        />
      </div>
    </>
  );
};
