import React from "react";
import { Title, Text } from "../dynamic.components";
import { useStore } from "src/store";

export const Microfluidics: React.FC = (): JSX.Element => {
  const {
    activeSubLink: { text },
  } = useStore();

  return (
    <>
      <Title title={text} />
      <div className="c-sidebar__body">
        <Text text="Microfluidics are designed for precise control of sample volumes, without diffusion between sample and buffer while in the fluidic channels. This is true for pure samples as well as complex cell cultures, serum, or plasma samples." />
      </div>
    </>
  );
};
