import React from "react";
import { useStore } from "src/store";
import { Title, Text } from "../dynamic.components";

export const TemperatureControl: React.FC = (): JSX.Element => {
  const {
    activeSubLink: { text },
  } = useStore();

  return (
    <>
      <Title title={text} />
      <div className="c-sidebar__body">
        <Text text="Surface plasmon resonance is highly sensitive to temperature. Precise control of the flow cell temperature is essential for reliable performance. The flow cell temperature is controlled within a range of 4°C to 40°C." />
        <Text text="The sample hotel is actively maintained at the same temperature as the sample compartment. For optimal temperature regulation, keep the sample hotel door closed — except when handling sample vials or plates — which is possible even during runs." />
        <Text text="The sample compartment temperature is controlled within the range 4°C to 40°C." />
      </div>
    </>
  );
};
