import React, { useEffect } from "react";
import { useStore } from "src/store";
import { Title, Spacer, Image, TextInline } from "../dynamic.components";
import {
  componentAnimationReadyToPlay,
  componentAnimationReadyToStop,
} from "src/utils/helper";
import { playCustomAnimation } from "src/services/animations";
import dual_sample_injection_tools from "src/assets/images/3_2_Increased_capacity_Sample_injection_tools_DUAL.jpg";
import sample_injection_tools from "src/assets/images/3_2_Increased_capacity_Sample_injection_tools_ABA.png";

export const SampleInjectionTools: React.FC = (): JSX.Element => {
  const {
    activeSubLink: { text },
    setAnimationStopped,
    sidebarStatus,
    animationStopped,
  } = useStore();

  useEffect(() => {
    if (componentAnimationReadyToPlay("3-2")) {
      playCustomAnimation();
    }
    if (componentAnimationReadyToStop("3-2")) {
      setAnimationStopped(true);
    }
  }, [sidebarStatus, animationStopped]);

  return (
    <>
      <Title title={text} />
      <div className="c-sidebar__body">
        <TextInline text="In addition to the standard process for sample injection, our innovative injection commands" />{" "}
        <TextInline style="italic" weight="bold" text="Dual" />{" "}
        <TextInline text="and" />{" "}
        <TextInline style="italic" weight="bold" text="ABA" />{" "}
        <TextInline text="facilitate samples to be combined without dispersion and expand application versatility supporting easy set-up of e.g. competition assays or buffer scouting experiments." />
        <Spacer x={3} />
        <TextInline text="The" />{" "}
        <TextInline style="italic" weight="bold" text="Dual" />{" "}
        <TextInline text="command injects two solutions in sequence with no intermediate washing steps. Kinetic fitting can be performed to the B-part of the" />{" "}
        <TextInline style="italic" weight="bold" text="Dual" />{" "}
        <TextInline text="command, using the 1:1 dissociation model in Biacore Insight Evaluation Software." />
        <Spacer />
        <TextInline style="italic" weight="bold" text="Dual" />{" "}
        <TextInline text="injection command can be used to study dissociation under different conditions and for epitope binning analysis to minimize the delay between antigen and second antibody." />
        <Spacer x={2} />
        <Image
          images={[
            {
              src: dual_sample_injection_tools,
              alt: "Increased capacity flow cells image",
              customWidth: "50%",
            },
          ]}
        />
        <Spacer x={3} />
        <TextInline text="The" />{" "}
        <TextInline style="italic" weight="bold" text="ABA" />{" "}
        <TextInline text="command allows two different solutions to be injected over the surface in the same cycle in the following order: solution A, solution B, then solution A." />{" "}
        <Spacer />
        <TextInline style="italic" weight="bold" text="ABA" />{" "}
        <TextInline text="lets you change the buffer conditions temporarily in the flow cell. Data gathered using" />{" "}
        <TextInline style="italic" weight="bold" text="ABA" />{" "}
        <TextInline text="can be fitted to kinetic models in Biacore Insight Evaluation Software. The injection command is well souted for buffer scouting and binding competition studies." />
        <Spacer x={2} />
        <Image
          images={[
            {
              src: sample_injection_tools,
              alt: "Increased capacity flow cells image",
              customWidth: "50%",
            },
          ]}
        />
      </div>
    </>
  );
};
